const payment_page_url = 'https://pay.dev.monite.com';

class DropInElement extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
    const linkId = this.getAttribute('link-id');
    const data = toBase64(JSON.stringify({ id: linkId }));

    const iframe = document.createElement('iframe');

    if (!linkId) {
      console.error('No link-id provided.');
      return;
    }

    if (!iframe) {
      console.error('Failed to create iframe.');
      return;
    }

    iframe.src = payment_page_url + '/?data=' + data;
    iframe.allow = 'clipboard-read; clipboard-write';
    iframe.style.height = '100%';
    iframe.style.width = '100%';
    iframe.style.border = 'none';

    if (this.shadowRoot) {
      this.shadowRoot.appendChild(iframe);
    }
  }
}

function toBase64(str: string) {
  try {
    return btoa(str);
  } catch (e) {
    throw new Error(`Invalid base64 string: ${str}`);
  }
}

customElements.define('monite-payment', DropInElement);
